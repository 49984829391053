'use client';
import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardActions,
	CardContent,
	Grid,
	IconButton,
	Rating,
	TextField,
	Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { IProductCart } from '@negotium/models';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useClientTranslation } from '@negotium/common';

import { ImageWithFallback } from '../ImageWithFallback';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';

type ProductCartShopProps = {
	lng: 'es' | 'en';
	product: IProductCart;
	onDeleteProduct: (cartUniqueId: string) => void;
	onEditAmount: (cartUniqueId: string, amount: number) => void;
	isCheckout?: boolean;
	disabledButton?: boolean;
};

export const ProductCartShop = ({
	lng,
	product,
	onDeleteProduct,
	onEditAmount,
	isCheckout = false,
	disabledButton = false,
}: ProductCartShopProps) => {
	const { t } = useClientTranslation(lng, ['catalog']);

	const [amount, setAmount] = useState<number>(0);
	const [editAmountCallback, setAditAmountCallback] = useState<boolean>(false);

	useEffect(() => {
		if (product.cartUniqueId) {
			setAmount(product.amount);
		}
	}, [product.cartUniqueId]);

	useEffect(() => {
		if (editAmountCallback) {
			onEditAmount(product.cartUniqueId, amount);
			setAditAmountCallback(false);
		}
	}, [editAmountCallback]);

	const handleIncrement = () => {
		setAmount(amount + 1);
		setAditAmountCallback(true);
	};

	const handleDecrement = () => {
		if (amount > 1) {
			setAmount(amount - 1);
			setAditAmountCallback(true);
		} else {
			onDeleteProduct(product.cartUniqueId);
		}
	};

	function handlerBlur() {
		setAditAmountCallback(true);
	}

	return (
		<Card
			sx={{
				marginTop: '10px',

				background: 'white',
				borderRadius: 5,
				minHeight: '100%',

				position: 'relative',
				...(isCheckout
					? {
							boxShadow: '-1px 3px 5px rgba(0, 0, 0, 0.4)',
					  }
					: {
							boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.4)',
					  }),
			}}
		>
			<CardContent
				sx={{
					padding: '0px',
					margin: '0px',
					paddingBottom: '0px !important',
				}}
			>
				<Box
					sx={{
						display: 'flex',
					}}
				>
					<Box
						position='relative'
						width='35%'
						sx={{
							aspectRatio:
								product.productImage?.width / product.productImage?.height,
						}}
					>
						<ImageWithFallback
							src={product.productImage?.url || ''}
							fallbackImage='/static/facebook600x315.png'
							alt={product.productName[lng]}
							width={product.productImage?.width}
							height={product.productImage?.height}
							style={{
								width: '100%',
								height: 'auto',
								display: 'flex',
								aspectRatio:
									product.productImage?.width / product.productImage?.height,
							}}
						/>
					</Box>

					<Box
						position='relative'
						sx={{ padding: '7px', minHeight: 'calc(100% - 14px)' }}
						width='65%'
					>
						<Box
							position='relative'
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '100%',
							}}
						>
							<Grid container columnSpacing={2}>
								<Grid item xs={10} display='flex' alignItems='center'>
									<Typography
										variant='ExplicationText'
										letterSpacing={1}
										fontWeight='500'
										lineHeight='15px'
									>
										{product.productName[lng]}
									</Typography>{' '}
								</Grid>
								<Grid
									item
									xs={2}
									display='flex'
									alignItems='center'
									justifyContent='flex-end'
								>
									<IconButton
										sx={{
											p: 0,
										}}
										aria-label={t('addToWishList')}
										color='primary'
										size='large'
										title={t('addToWishList')}
										onClick={() => {}}
									>
										<FavoriteBorderIcon
											fontSize='medium'
											sx={{
												color: (theme) => theme.palette.secondary.main,
											}}
										/>
									</IconButton>
								</Grid>
								<Grid item xs={12} md={6} display='flex' alignItems='center'>
									<Typography
										variant='ExplicationText'
										fontSize='clamp(0.7rem,3.5vw,1rem)'
										color='secondary.main'
										letterSpacing={1}
										fontWeight='600'
										marginTop={1}
									>
										{`${formatCurrency(
											product.productPrice,
											product.currency
										)} ${product.currency}`}
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									display='flex'
									alignItems='center'
									justifyContent={{ md: 'flex-end', xs: 'flex-start' }}
								>
									<Rating
										value={2}
										size='small'
										icon={
											<StarIcon
												fontSize='inherit'
												sx={{
													color: (theme) => theme.palette.secondary.main,
												}}
											/>
										}
										emptyIcon={
											<StarBorderIcon
												fontSize='inherit'
												sx={{
													color: (theme) => theme.palette.secondary.main,
												}}
											/>
										}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								columnSpacing={2}
								sx={{ display: { sm: 'block', xs: 'none' } }}
							>
								<Grid
									item
									xs={12}
									display='flex'
									alignItems='center'
									justifyContent='space-between'
								>
									<ButtonGroup
										variant='text'
										aria-label={t('changeAmountOfProduct')}
										title={t('changeAmountOfProduct')}
										sx={{
											height: '30px',
										}}
									>
										<Button
											variant='outlined'
											onClick={handleDecrement}
											aria-label={t('decrementAmount')}
											title={t('decrementAmount')}
											disabled={disabledButton}
										>
											-
										</Button>
										<TextField
											type='number'
											value={amount}
											InputProps={{
												readOnly: disabledButton,
											}}
											inputProps={{
												sx: {
													minHeight: '30px',
													py: 0,
													px: 1,
													borderRadius: 0,
													textAlign: 'center',
													justifyContent: 'center',
													display: 'flex',
													'-moz-appearance': 'textfield',
													'::-webkit-outer-spin-button': {
														'-webkit-appearance': 'none',
														margin: 0,
													},
													'::-webkit-inner-spin-button': {
														'-webkit-appearance': 'none',
														margin: 0,
													},
												},
											}}
											onBlur={disabledButton ? () => {} : () => handlerBlur()}
											onChange={
												disabledButton
													? () => {}
													: (e) => setAmount(Number(e.target.value))
											}
											sx={{
												width: '4em',
												borderRadius: 0,
												'&>*': {
													'&.MuiOutlinedInput-root': {
														borderRadius: 0,
													},
													fieldset: {
														borderColor: (theme) => theme.palette.primary.main,
													},
												},
											}}
										/>
										<Button
											variant='outlined'
											onClick={handleIncrement}
											aria-label={t('incrementAmount')}
											title={t('incrementAmount')}
											disabled={disabledButton}
										>
											+
										</Button>
									</ButtonGroup>

									{disabledButton ? null : (
										<Button
											variant='contained'
											size='small'
											color='error'
											aria-label={t('deleteProductOfCartShop')}
											title={t('deleteProductOfCartShop')}
											onClick={() => onDeleteProduct(product.cartUniqueId)}
										>
											<DeleteIcon />
										</Button>
									)}
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			</CardContent>
			<CardActions sx={{ display: { xs: 'block', sm: 'none' } }}>
				<Grid
					item
					xs={12}
					display='flex'
					alignItems='center'
					justifyContent='space-between'
				>
					<ButtonGroup
						variant='text'
						aria-label={t('changeAmountOfProduct')}
						title={t('changeAmountOfProduct')}
						sx={{
							height: '30px',
						}}
					>
						<Button
							variant='outlined'
							onClick={handleDecrement}
							aria-label={t('decrementAmount')}
							title={t('decrementAmount')}
							disabled={disabledButton}
						>
							-
						</Button>
						<TextField
							type='number'
							value={amount}
							InputProps={{
								readOnly: disabledButton,
							}}
							inputProps={{
								sx: {
									minHeight: '30px',
									py: 0,
									px: 1,
									borderRadius: 0,
									textAlign: 'center',
									justifyContent: 'center',
									display: 'flex',
									'-moz-appearance': 'textfield',
									'::-webkit-outer-spin-button': {
										'-webkit-appearance': 'none',
										margin: 0,
									},
									'::-webkit-inner-spin-button': {
										'-webkit-appearance': 'none',
										margin: 0,
									},
								},
							}}
							onBlur={disabledButton ? () => {} : () => handlerBlur()}
							onChange={
								disabledButton
									? () => {}
									: (e) => setAmount(Number(e.target.value))
							}
							sx={{
								width: '4em',
								borderRadius: 0,
								'&>*': {
									'&.MuiOutlinedInput-root': {
										borderRadius: 0,
									},
									fieldset: {
										borderColor: (theme) => theme.palette.primary.main,
									},
								},
							}}
						/>
						<Button
							variant='outlined'
							onClick={handleIncrement}
							aria-label={t('incrementAmount')}
							title={t('incrementAmount')}
							disabled={disabledButton}
						>
							+
						</Button>
					</ButtonGroup>

					{disabledButton ? null : (
						<Button
							variant='contained'
							size='small'
							color='error'
							aria-label={t('deleteProductOfCartShop')}
							title={t('deleteProductOfCartShop')}
							onClick={() => onDeleteProduct(product.cartUniqueId)}
						>
							<DeleteIcon />
						</Button>
					)}
				</Grid>
			</CardActions>
		</Card>
	);
};
